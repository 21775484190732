<template>
  <div>
      <div class="Box">
          <img class="imgBox" src="../../assets/img/home_successful_signing_icon.png" alt="">
          <p class="text">签约成功</p>
          <van-button round type="default" plain size="mini" @click="goRouter('/index')">返回首页</van-button>

      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.imgBox{
    width: 108px;
    height: 108px;
}
.Box{
    position: absolute;
    left: 50%;
    top: 10%;
    transform: translate(-50%,0);
    text-align: center;
}
.Box .text{
    font-size: .43rem;
    color: #000A33FF;
}
.Box ::v-deep .van-button{
    padding: .4rem .5rem;
    margin: .5rem 0;
}
</style>